<template>
   <el-container class="recordadd p15">
    <el-header class="box add bb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>菜单管理</el-breadcrumb-item>
       <el-breadcrumb-item  to="/admin/menu">菜单列表</el-breadcrumb-item>
       <el-breadcrumb-item   to="/admin/btnList">按钮列表</el-breadcrumb-item>
       <el-breadcrumb-item v-if="menubtn_id">编辑按钮</el-breadcrumb-item>
       <el-breadcrumb-item v-else>新增按钮</el-breadcrumb-item>
    </el-breadcrumb>
        <div>
          
          <el-button  class="back_btn"  size="medium" plain @click="$router.back()">返回</el-button>
        </div>
    </el-header>
    <el-main  class="pt0">
      <el-form  :inline="true" status-icon ref="formInline"  :model="formInline"  class="demo-ruleForm">
     <el-row class="box mt20  f14">
        
        <el-col :span="11" class="tl">
           <el-form-item class="w" label-width="28%" label="按钮ID：" size="medium" prop="menubtn_id" :rules="[{required: true,message: '请输入菜单ID', trigger: 'blur' }]">
           <el-input placeholder="请输入按钮ID" v-model="formInline.menubtn_id" class="input-with-select">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11" class="tl" >
           <el-form-item class="w" label-width="28%" label="页面按钮名称：" size="medium" prop="menubtn_title" :rules="[{required: true,message: '请输入页面按钮名称', trigger: 'blur' }]">
           <el-input placeholder="请输入页面按钮名称" v-model="formInline.menubtn_title" class="input-with-select">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11" class="tl">
           <el-form-item class="w" label-width="28%" label="页面按钮图片：" size="medium" >
           <el-input placeholder="请输入页面按钮图片" v-model="formInline.menubtn_img" class="input-with-select">
            </el-input>
          </el-form-item>
        </el-col>
        
         <el-col :span="22" class="tl" :offset="1">
           <el-button  class="" size="medium" type="primary" @click="submitForm('formInline')">提交</el-button>
        </el-col>
     </el-row>
     
 </el-form>
    </el-main>
   </el-container>
</template>
<script>
import axios from '../../../util/api'
// import { tengxuncloud } from '../../../util/tengxun'
import { local,session} from '../../../util/util'
import {imageUrl} from '../../../config/index'
export default {
  data () {
    return {
        user:'',
      formInline: {
         menu_id:'',
        menubtn_img: '',
        menubtn_id: '',
        menubtn_title:'',
      },
      menubtn_id:'',
      Options1:[{ label: "一级", value: "1" },{ label: "二级", value: "2" }],
      Options2:[{ label: "否", value: "1" },{ label: "是", value: "2" }],
    }
  },
   created () {
       this.user = local.get('user');
       this.formInline.menu_id =session.get('params2').id?String(session.get('params2').id):'';
    this.menubtn_id =session.get('params2').id1?String(session.get('params2').id1):'';
       if(this.menubtn_id){this.init()}
           
  },
  methods: {
       init () {
          axios.get("/pc/menu-button/single",{menubtn_id:String(this.menubtn_id)}).then(v => {
           this.formInline.menubtn_img=v.data.menu_button.menubtn_img;
           this.formInline.menubtn_title=v.data.menu_button.menubtn_title;
           this.formInline.menubtn_id=v.data.menu_button.menubtn_id;
          });
       },
      
         submitForm(formInline){
         this.$refs[formInline].validate((valid) => {
        if (valid) {
            if(this.menubtn_id){
                 axios.put('/pc/menu-button/update', this.formInline).then((v) => {
      this.$router.back()
      })

            }else{
                axios.post('/pc/menu-button/save', this.formInline).then((v) => {
      this.$router.back()
      })
            }
        
        }else{
           this.$message.error('请检查表单必填项');
            return false
        }
         })
      
      },
  }
}
</script>
<style lang="scss" scoped>
.recordadd{
  overflow-x: hidden;
  .add{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .back_btn{background:rgba(247,248,249,1);border:1px solid rgba(217,222,225,1);}
  .recordadd-title{height: 34px;line-height: 34px;border-left: 2px solid #788287}
 /deep/ .el-input-group__append .el-button--primary .el-icon-search{color: #fff}
 /deep/ .el-checkbox-button__inner{    border-left: 1px solid #DCDFE6;
    border-radius: 4px !important;}
   /deep/ .no-header .has-gutter{display: none}
  .people-message .el-form-item { margin-bottom: 0px;}
  .recordadd-upload .el-upload-list__item{width: 160px;height: 160px;float: left;margin-right: 10px;
   /deep/ .el-upload{width: 160px;height: 160px;line-height: 160px;}}
      /deep/ .disabled .el-upload--text {
    display: none !important;
}
}

</style>